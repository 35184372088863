

#root, body, html {
  font-family: 'Inter';
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

html * {
  box-sizing: border-box;
}

body * ::-webkit-scrollbar-track {
  display: none;
  border-color: transparent;
  background-color: transparent;
}

body *::-webkit-scrollbar * {
  background: transparent;
}

body *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body *::-webkit-scrollbar-corner {
  background-color: transparent;
}

body *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(126, 126, 130, 0.7);
}

body *::-webkit-scrollbar-thumb:hover {
  background-color: rgb(126, 126, 130);
}

body *::-webkit-scrollbar-thumb *  {
  -webkit-box-shadow: inset 0 0 0;
}

.mapboxgl-popup-content {
  padding: 0;
}

.mapboxgl-popup-tip {
  opacity: 0;
}

.mapboxgl-popup {
  top: -10px;
}

.smallDatePicker .MuiInputBase-input {
  padding: 8px;
}

.smallDatePicker.fullWidth {
  width: 100%;
}

.withoutShrinkLabel .MuiInputLabel-shrink {
  display: none;
}

.withoutShrinkLabel .MuiAutocomplete-input::placeholder {
  font-weight: 600;
  font-size: 14px;
} 

.withoutShrinkLabel legend {
  display: none;
}

.withoutShrinkLabel fieldset {
  top: 0;
}

.withIcon .MuiFormLabel-root {
  left: 20px;
  font-weight: 600;
  font-size: 14px;
  top: 2px;    
  color: #272E35;
}

.withIcon .MuiInputBase-root {
  flex-wrap: nowrap!important;
}